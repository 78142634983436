angular.module('app.controllers').controller 'ExhibitorsListCtrl', [
  '$scope', '$rootScope', 'APIReadTable', 'Table', '$cookies', 'APIRead', '$location', '$q'
].append (self, root, APIReadTable, Table, $cookies, APIRead, $location, $q) ->

  tableName   = 'c.exh.' + (root.user?.shortcode || 'test')
  cookiesName = 't.' + tableName

  self.searchTerm = ""

  self.listMode = false
  self.toggleList = () ->
    self.listMode = !self.listMode

  self.toggleOrder = () ->
    self.exhibitors.params.reverse = !self.exhibitors.params.reverse

  defaultParams = 
    subject_area: "0"
    halls: ""
    brands: ""
    categories: ""
    country: ""

  self.tempParams = $cookies.getObject(cookiesName) || defaultParams  
  

  #
  # Main request
  #

  self.exhibitors = Table
    name: tableName
    type: "partial"
    resource: APIReadTable
    cookies: true
    params: 
      limit: 36
      orderby: "exhibitor_name"
      entry_type: "exhibitors"
      subject_area: if self.tempParams.subject_area then self.tempParams.subject_area else "0"

  self.page = 1
  self.moreLoad = false
  self.loadMore = () ->
    return if self.page >= self.exhibitors.data.pages
    
    self.page++ 
    
    params = angular.copy(self.exhibitors.params)
    params.page = self.page

    self.moreLoad = true
    APIRead.query params, (data) ->
      self.more = data
      self.exhibitors.data.display = self.exhibitors.data.display.concat(self.more)
      self.moreLoad = false


  self.applyFilter = () ->
    angular.extend(self.exhibitors.params, self.tempParams)

  self.resetFilter = (offcanvas = false) ->
    self.tempParams = angular.copy(defaultParams)
    self.applyFilter()
    if filterOffcanvasEl?
      filterOffcanvas.hide() if offcanvas

  self.setSubject = (subject) ->
    subjects = self.exhibitors.params.subject_area?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
    if subjects.length == 1
      if subject in subjects
        subjects = [if subject == 1 then 0 else 1]
      else
        subjects.push subject
    else if subjects.length == 2
      subjects.splice(subjects.indexOf(subject), 1)

    self.exhibitors.params.subject_area = subjects.join("|")
    self.page = 1
    self.$emit 'list:filtered'

  self.getSubject = (subject) ->
    if (Number.isInteger(self.exhibitors?.params.subject_area))
      self.exhibitors.params.subject_area = "#{self.exhibitors.params.subject_area}"
    
    subjects = self.exhibitors.params.subject_area?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
    return subject in subjects
    

  #
  # Filter offcanvas
  #

  fp1 = APIRead.query {entry_type: 'exhibitors', stats: 'country', full: true}
  fp2 = APIRead.query {entry_type: 'exhibitors', stats: 'booths', full: true}
  fp3 = APIRead.query {entry_type: 'exhibitors', stats: 'brands', full: true}
  fp4 = APIRead.query {entry_type: 'exhibitors', stats: 'categories', full: true}

  $q.all([fp1, fp2, fp3, fp4])
    .then (responses) ->
      self.fcountry = responses[0]
      self.fhalls = responses[1]
      self.fbrands = responses[2]
      self.fcategories = responses[3]
    .catch (response) ->
      UI.notify false, response.data.error

  self.fsubject = [
    {
      id: 0
      title: "Blühendes Österreich"
    }
    {
      id: 1
      title: "Urlaub & Ausflug"
    }
  ]

  filterOffcanvasEl = document.getElementById('exhFilterOffcanvs')
  if filterOffcanvasEl != null
    filterOffcanvas = new bootstrap.Offcanvas(filterOffcanvasEl)

  self.toggleFilter = () ->
    filterOffcanvas.toggle() if filterOffcanvas
  
  self.lvl = null
  
  self.lvls = 
    halls: "Halle"
    brands: "Marken"
    categories: "Kategorie"
    country: "Land"

  self.isLvl1 = () ->
    return true unless self.lvl?
    return false

  self.isLvl2 = (key = null) ->
    return self.lvl unless key?
    return self.lvl == key

  self.toggleLvl = (key = null) ->
    key = null if self.isLvl2(key)
    self.lvl = key

    self.offCatFilter = ''
    self.offBraFilter = ''

  self.toggleFilter = () ->
    filterOffcanvas.toggle() if filterOffcanvas

  self.offChanged = (key) ->
    self.offData.params[key] = self.offParams[key].join("|")

  loadOffCount = (offparams) ->
    offparams.offcount = true

    self.offData = Table
      name: tableName + ".off"
      type: "partial"
      resource: APIReadTable    
      cookies: true
      params: offparams

  self.applyOff = () ->
    offparams_2 = angular.copy(self.offData.params)
    delete(offparams_2.offcount)
    self.exhibitors.params = offparams_2
    filterOffcanvas.hide()

  if filterOffcanvasEl != null
    filterOffcanvasEl.addEventListener('show.bs.offcanvas', () ->
      offparams_1 = angular.copy(self.exhibitors.params)

      self.offParams = 
        halls:         offparams_1.halls?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
        brands:        offparams_1.brands?.split("|").filter((cat) -> return cat) || []
        categories:    offparams_1.categories?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
        country:       offparams_1.country?.split("|").filter((cat) -> return cat) || []

      loadOffCount(offparams_1)
    )

    filterOffcanvasEl.addEventListener('hide.bs.offcanvas', () ->
      self.lvl = null
      self.offCatFilter = ''
      self.offBraFilter = ''
    )


  self.$watch 'exhibitors.params', (newObj, oldObj) ->
    as2 = newObj?.halls?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
    as3 = newObj?.brands?.split("|").filter((cat) -> return cat) || []
    as4 = newObj?.categories?.split("|").filter((cat) -> return cat || cat == 0).map((str) -> return Number(str)) || []
    as5 = newObj?.country?.split("|").filter((cat) -> return cat) || []

    self.filterLength = as2.length + as3.length + as4.length + as5.length
  , true

  #
  # Actions
  #

  params = $location.search()

  self.actionModalEl = document.getElementById('actionModal')
  
  if self.actionModalEl
    self.actionModal = bootstrap.Modal.getOrCreateInstance(self.actionModalEl)

    self.actionModalEl.addEventListener('show.bs.modal', (event) ->
      if self.actionTimeout
        $timeout.cancel(self.actionTimeout)
      self.actionTimeout = $timeout () ->
        self.actionModal.hide()
      , 5000
      self.$applyAsync()
    )

    self.actionModalEl.addEventListener('hide.bs.modal', (event) ->
      self.$applyAsync()
    )

    if params && angular.isDefined(params.action) && angular.isDefined(params.exhibitor_id) && params.action == 'add'
      p_id = parseInt(params.exhibitor_id)
      self.toggleFavs('ex', {id: p_id}) unless self.isFavs('ex', p_id)
      self.actionModal.show()


angular.module('app.controllers').controller 'ExhibitorsBlocksCtrl', [
  '$scope', '$rootScope', 'APIRead'
].append (self, root, APIRead) ->
  
  #
  # Random exhbitors
  #

  self.localLoad = false
  # subject_area = 0 for BluO 
  # subject_area = 1 for Urlaub
  self.exhibitors = APIRead.query {random: true, full: true, splice: true, with: 'company_logo', subject_area: 0}, (data) ->
    self.localLoad = true

  #
  # Slider settings
  #

  self.moreRandomExhibitors =
    infinite: true
    dots: true
    slidesToShow: 5
    slidesToScroll: 2
    arrows: true
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-left"></use></svg></button>'
    nextArrow: '<button type="button" class="slick-arrow slick-next"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-right"></use></svg></button>'

    responsive: [
      {
        breakpoint: 1280
        settings: 
          arrows: false
      }
      {
        breakpoint: 1023
        settings: 
          arrows: false
          slidesToShow: 4
      }
      {
        breakpoint: 568
        settings: 
          centerMode: false
          variableWidth: false
          slidesToShow: 2
          arrows: false
      }
    ]


