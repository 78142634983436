var indexOf = [].indexOf;

angular.module('app.controllers').controller('ExhibitorsListCtrl', ['$scope', '$rootScope', 'APIReadTable', 'Table', '$cookies', 'APIRead', '$location', '$q'].append(function(self, root, APIReadTable, Table, $cookies, APIRead, $location, $q) {
  var cookiesName, defaultParams, filterOffcanvas, filterOffcanvasEl, fp1, fp2, fp3, fp4, loadOffCount, p_id, params, ref, tableName;
  tableName = 'c.exh.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'test');
  cookiesName = 't.' + tableName;
  self.searchTerm = "";
  self.listMode = false;
  self.toggleList = function() {
    return self.listMode = !self.listMode;
  };
  self.toggleOrder = function() {
    return self.exhibitors.params.reverse = !self.exhibitors.params.reverse;
  };
  defaultParams = {
    subject_area: "0",
    halls: "",
    brands: "",
    categories: "",
    country: ""
  };
  self.tempParams = $cookies.getObject(cookiesName) || defaultParams;
  
  // Main request

  self.exhibitors = Table({
    name: tableName,
    type: "partial",
    resource: APIReadTable,
    cookies: true,
    params: {
      limit: 36,
      orderby: "exhibitor_name",
      entry_type: "exhibitors",
      subject_area: self.tempParams.subject_area ? self.tempParams.subject_area : "0"
    }
  });
  self.page = 1;
  self.moreLoad = false;
  self.loadMore = function() {
    var params;
    if (self.page >= self.exhibitors.data.pages) {
      return;
    }
    self.page++;
    params = angular.copy(self.exhibitors.params);
    params.page = self.page;
    self.moreLoad = true;
    return APIRead.query(params, function(data) {
      self.more = data;
      self.exhibitors.data.display = self.exhibitors.data.display.concat(self.more);
      return self.moreLoad = false;
    });
  };
  self.applyFilter = function() {
    return angular.extend(self.exhibitors.params, self.tempParams);
  };
  self.resetFilter = function(offcanvas = false) {
    self.tempParams = angular.copy(defaultParams);
    self.applyFilter();
    if (typeof filterOffcanvasEl !== "undefined" && filterOffcanvasEl !== null) {
      if (offcanvas) {
        return filterOffcanvas.hide();
      }
    }
  };
  self.setSubject = function(subject) {
    var ref1, subjects;
    subjects = ((ref1 = self.exhibitors.params.subject_area) != null ? ref1.split("|").filter(function(cat) {
      return cat || cat === 0;
    }).map(function(str) {
      return Number(str);
    }) : void 0) || [];
    if (subjects.length === 1) {
      if (indexOf.call(subjects, subject) >= 0) {
        subjects = [subject === 1 ? 0 : 1];
      } else {
        subjects.push(subject);
      }
    } else if (subjects.length === 2) {
      subjects.splice(subjects.indexOf(subject), 1);
    }
    self.exhibitors.params.subject_area = subjects.join("|");
    self.page = 1;
    return self.$emit('list:filtered');
  };
  self.getSubject = function(subject) {
    var ref1, ref2, subjects;
    if (Number.isInteger((ref1 = self.exhibitors) != null ? ref1.params.subject_area : void 0)) {
      self.exhibitors.params.subject_area = `${self.exhibitors.params.subject_area}`;
    }
    subjects = ((ref2 = self.exhibitors.params.subject_area) != null ? ref2.split("|").filter(function(cat) {
      return cat || cat === 0;
    }).map(function(str) {
      return Number(str);
    }) : void 0) || [];
    return indexOf.call(subjects, subject) >= 0;
  };
  
  // Filter offcanvas

  fp1 = APIRead.query({
    entry_type: 'exhibitors',
    stats: 'country',
    full: true
  });
  fp2 = APIRead.query({
    entry_type: 'exhibitors',
    stats: 'booths',
    full: true
  });
  fp3 = APIRead.query({
    entry_type: 'exhibitors',
    stats: 'brands',
    full: true
  });
  fp4 = APIRead.query({
    entry_type: 'exhibitors',
    stats: 'categories',
    full: true
  });
  $q.all([fp1, fp2, fp3, fp4]).then(function(responses) {
    self.fcountry = responses[0];
    self.fhalls = responses[1];
    self.fbrands = responses[2];
    return self.fcategories = responses[3];
  }).catch(function(response) {
    return UI.notify(false, response.data.error);
  });
  self.fsubject = [
    {
      id: 0,
      title: "Blühendes Österreich"
    },
    {
      id: 1,
      title: "Urlaub & Ausflug"
    }
  ];
  filterOffcanvasEl = document.getElementById('exhFilterOffcanvs');
  if (filterOffcanvasEl !== null) {
    filterOffcanvas = new bootstrap.Offcanvas(filterOffcanvasEl);
  }
  self.toggleFilter = function() {
    if (filterOffcanvas) {
      return filterOffcanvas.toggle();
    }
  };
  self.lvl = null;
  self.lvls = {
    halls: "Halle",
    brands: "Marken",
    categories: "Kategorie",
    country: "Land"
  };
  self.isLvl1 = function() {
    if (self.lvl == null) {
      return true;
    }
    return false;
  };
  self.isLvl2 = function(key = null) {
    if (key == null) {
      return self.lvl;
    }
    return self.lvl === key;
  };
  self.toggleLvl = function(key = null) {
    if (self.isLvl2(key)) {
      key = null;
    }
    self.lvl = key;
    self.offCatFilter = '';
    return self.offBraFilter = '';
  };
  self.toggleFilter = function() {
    if (filterOffcanvas) {
      return filterOffcanvas.toggle();
    }
  };
  self.offChanged = function(key) {
    return self.offData.params[key] = self.offParams[key].join("|");
  };
  loadOffCount = function(offparams) {
    offparams.offcount = true;
    return self.offData = Table({
      name: tableName + ".off",
      type: "partial",
      resource: APIReadTable,
      cookies: true,
      params: offparams
    });
  };
  self.applyOff = function() {
    var offparams_2;
    offparams_2 = angular.copy(self.offData.params);
    delete offparams_2.offcount;
    self.exhibitors.params = offparams_2;
    return filterOffcanvas.hide();
  };
  if (filterOffcanvasEl !== null) {
    filterOffcanvasEl.addEventListener('show.bs.offcanvas', function() {
      var offparams_1, ref1, ref2, ref3, ref4;
      offparams_1 = angular.copy(self.exhibitors.params);
      self.offParams = {
        halls: ((ref1 = offparams_1.halls) != null ? ref1.split("|").filter(function(cat) {
          return cat || cat === 0;
        }).map(function(str) {
          return Number(str);
        }) : void 0) || [],
        brands: ((ref2 = offparams_1.brands) != null ? ref2.split("|").filter(function(cat) {
          return cat;
        }) : void 0) || [],
        categories: ((ref3 = offparams_1.categories) != null ? ref3.split("|").filter(function(cat) {
          return cat || cat === 0;
        }).map(function(str) {
          return Number(str);
        }) : void 0) || [],
        country: ((ref4 = offparams_1.country) != null ? ref4.split("|").filter(function(cat) {
          return cat;
        }) : void 0) || []
      };
      return loadOffCount(offparams_1);
    });
    filterOffcanvasEl.addEventListener('hide.bs.offcanvas', function() {
      self.lvl = null;
      self.offCatFilter = '';
      return self.offBraFilter = '';
    });
  }
  self.$watch('exhibitors.params', function(newObj, oldObj) {
    var as2, as3, as4, as5, ref1, ref2, ref3, ref4;
    as2 = (newObj != null ? (ref1 = newObj.halls) != null ? ref1.split("|").filter(function(cat) {
      return cat || cat === 0;
    }).map(function(str) {
      return Number(str);
    }) : void 0 : void 0) || [];
    as3 = (newObj != null ? (ref2 = newObj.brands) != null ? ref2.split("|").filter(function(cat) {
      return cat;
    }) : void 0 : void 0) || [];
    as4 = (newObj != null ? (ref3 = newObj.categories) != null ? ref3.split("|").filter(function(cat) {
      return cat || cat === 0;
    }).map(function(str) {
      return Number(str);
    }) : void 0 : void 0) || [];
    as5 = (newObj != null ? (ref4 = newObj.country) != null ? ref4.split("|").filter(function(cat) {
      return cat;
    }) : void 0 : void 0) || [];
    return self.filterLength = as2.length + as3.length + as4.length + as5.length;
  }, true);
  
  // Actions

  params = $location.search();
  self.actionModalEl = document.getElementById('actionModal');
  if (self.actionModalEl) {
    self.actionModal = bootstrap.Modal.getOrCreateInstance(self.actionModalEl);
    self.actionModalEl.addEventListener('show.bs.modal', function(event) {
      if (self.actionTimeout) {
        $timeout.cancel(self.actionTimeout);
      }
      self.actionTimeout = $timeout(function() {
        return self.actionModal.hide();
      }, 5000);
      return self.$applyAsync();
    });
    self.actionModalEl.addEventListener('hide.bs.modal', function(event) {
      return self.$applyAsync();
    });
    if (params && angular.isDefined(params.action) && angular.isDefined(params.exhibitor_id) && params.action === 'add') {
      p_id = parseInt(params.exhibitor_id);
      if (!self.isFavs('ex', p_id)) {
        self.toggleFavs('ex', {
          id: p_id
        });
      }
      return self.actionModal.show();
    }
  }
}));

angular.module('app.controllers').controller('ExhibitorsBlocksCtrl', ['$scope', '$rootScope', 'APIRead'].append(function(self, root, APIRead) {
  
  // Random exhbitors

  self.localLoad = false;
  // subject_area = 0 for BluO 
  // subject_area = 1 for Urlaub
  self.exhibitors = APIRead.query({
    random: true,
    full: true,
    splice: true,
    with: 'company_logo',
    subject_area: 0
  }, function(data) {
    return self.localLoad = true;
  });
  
  // Slider settings

  return self.moreRandomExhibitors = {
    infinite: true,
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: true,
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-left"></use></svg></button>',
    nextArrow: '<button type="button" class="slick-arrow slick-next"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-right"></use></svg></button>',
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 568,
        settings: {
          centerMode: false,
          variableWidth: false,
          slidesToShow: 2,
          arrows: false
        }
      }
    ]
  };
}));
